<template>
	<div class="tab-content">
		<table class="table table-striped brand draft-list">
			<thead>
				<tr>
					<th class="col-1"></th>
					<th class="col-2">User Name</th>
					<th class="col-3">Course Name</th>
					<th class="col-3 text-center"></th>
					<th class="col-6"></th>
				</tr>
			</thead>
			<tbody>
				<ReportRowData
					v-for="certificate in displayedCertificates"
					:key="certificate.id"
					:certificate="certificate"
					:dateType="'created'"
					:displayPopup="displayPopup"
					@closePopup="closePopup"
				>
					<template #buttons>
						<button
							v-for="(btn, index) in buttons"
							class="report-list__button primary-text"
							:key="index"
							@click="btn.action(certificate)"
						>
							{{ btn.title }}
						</button>
					</template>
				</ReportRowData>
			</tbody>
		</table>
	</div>
	<!-- <v-container>
		<v-row>
			<v-col>
				<ReportRowData
					v-for="certificate in displayedCertificates"
					:key="certificate.id"
					:certificate="certificate"
					:dateType="'created'"
					:displayPopup="displayPopup"
					@closePopup="closePopup"
				>
					<template #buttons>
						<button
							v-for="(btn, index) in buttons"
							class="report-list__button primary-text"
							:key="index"
							@click="btn.action(certificate)"
						>
							{{ btn.title }}
						</button>
					</template>
				</ReportRowData>
			</v-col>
		</v-row>
	</v-container> -->
</template>

<script>
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import ReportRowData from "./components/ReportRowData.vue";

export default {
	name: "CertificatesList",
	components: { AdminDefaultSubheader, ReportRowData },
	props: ["displayedCertificates"],
	data() {
		return {
			buttons: [
				{
					title: "Quiz Results",
					action: () => {
						console.log("click");
					}
				},
				{
					title: "View Certificate",
					action: certificate => this.seeCertificate(certificate)
				}
				// {
				// 	title: "Download PDF",
				// 	action: () => {
				// 		console.log("click");
				// 	}
				// }
			],
			displayPopup: false
		};
	},
	methods: {
		seeResult() {
			console.log("results");
		},
		seeCertificate(certificate) {
			if (certificate.completed_at === null) return;
			this.displayPopup = true;
		},
		closePopup() {
			this.displayPopup = false;
		}
	}
};
</script>

<style></style>
